import React, { useContext } from 'react'
import { LanguageContext } from '../App'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import ProfileLink from '../components/home/ProfileLink'
import ContentItem from '../components/home/ContentItem'

const Home = () => {
    const language = useContext(LanguageContext);

    const enIntro = "Hi! My name is Chen and I'm currently a Master's student in Computer Science. This is my portfolio website of some projects I have completed. I am looking forward to further developing my interest and skills in this field!"

    const frIntro = "Bonjour! Je m'apelle Chen et je suis présentement une étudiante de maîtrise en informatique. Ce site est un portfolio de quelques projets que j'ai réalisés. J'ai hâte de continuer à approfondir mes intérêts et connaissances dans ce domaine!"

    return (
        <div className='h-screen w-screen flex flex-row items-center justify-center content-center flex-wrap bg-grid'>
            <div className='appear-top-animation max-w-3xl min-h-80 mx-4 my-8 mb-8 p-6 border border-lightColor rounded-3xl bg-darkColor shadow-type-2 flex flex-col justify-start items-start gap-4 flex-wrap hover-animation'>
                <div className='text-7xl font-bold text-lightColor'>Chen <span className='text-primaryColor'>Yue</span></div>

                <div className='flex flex-row items-center justify-start gap-6'>
                    <ProfileLink link='https://github.com/cyue49' label='GitHub' icon={faGithub} />
                    <ProfileLink link='https://www.linkedin.com/in/chen-yue-yc' label='LinkedIn' icon={faLinkedin} />
                </div>

                <div className='py-2'>{language === 'en' ? enIntro : frIntro}</div>
            </div>

            <div className='mx-4 px-2 py-8 lg:py-2 flex flex-row lg:flex-col gap-6 items-center lg:items-start justify-start lg:min-h-80 overflow-scroll no-scrollbar appear-top-animation-50'>
                <ContentItem label={language === 'en' ? 'Projects' : 'Projets'} path={'projects'} />
            </div>
        </div>
    )
}

export default Home
